import * as React from "react"

import {Link} from 'gatsby'
import Layout from '../../../components/layout/layout'

const pageTitle = "RST"

const IndexPage = () => {
    return (
        <Layout pageTitle={pageTitle}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                            <h3 className="pt-4 pb-4">
                                <a href="/en/pages/frequencies/">香港各區之中繼站 Hong Kong Repeaters</a>
                            </h3>
                            <hr />

                                <p className="text-muted">Jun 24, 2014</p>


                                <div className="panel panel-blue">
                                    <div className="panel-heading">CSV 下載</div>
                                    <div className="panel-body">
                                        <p>香港業餘無線電會 HKARA 有專頁列出<a
                                            href="http://www.hkara.org.hk/html/book_review/VR2_Repeater_CH-list_jan14.htm">分佈香港各區之中繼站</a>。


                                        </p>


                                        <p>請用 CTCSS 110.9Hz 去接收及發送話音。</p>
                                    </div>
                                </div>


                                <table className="table table-striped">
                                    <tbody>
                                    <tr>
                                        <td>
                                            Title / Callsign
                                        </td>
                                        <td>
                                            QTH 登記設台位
                                        </td>
                                        <td>
                                            Downlink 下行頻 (MHz)
                                        </td>
                                        <td>
                                            Uplink 上行頻 (MHz)
                                        </td>
                                        <td>
                                            TSQL
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>
                                            VR2RCV
                                        </td>
                                        <td>
                                            Yau Ma Tei 油麻地
                                        </td>
                                        <td>
                                            145.25
                                        </td>
                                        <td>
                                            144.65
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2CHK
                                        </td>
                                        <td>
                                            Tai Po 大埔
                                        </td>
                                        <td>
                                            145.7
                                        </td>
                                        <td>
                                            145.3
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2Z
                                        </td>
                                        <td>
                                            Tai Tung Shan 大東山
                                        </td>
                                        <td>
                                            144.425
                                        </td>
                                        <td>
                                            144.825
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2TKW
                                        </td>
                                        <td>
                                            Tokwawan 土瓜灣
                                        </td>
                                        <td>
                                            145.575
                                        </td>
                                        <td>
                                            144.975
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2A
                                        </td>
                                        <td>
                                            Mid Levels 半山
                                        </td>
                                        <td>
                                            145.625
                                        </td>
                                        <td>
                                            145.025
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2KP
                                        </td>
                                        <td>
                                            Tate's Cair 大老山
                                        </td>
                                        <td>
                                            145.65
                                        </td>
                                        <td>
                                            145.05
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2HAC
                                        </td>
                                        <td>
                                            Sai Kung 西貢
                                        </td>
                                        <td>
                                            145.675
                                        </td>
                                        <td>
                                            145.05
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2HKR
                                        </td>
                                        <td>
                                            North Point 北角
                                        </td>
                                        <td>
                                            145.7
                                        </td>
                                        <td>
                                            145.1
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2R
                                        </td>
                                        <td>
                                            Tai Mo Shan 大帽山
                                        </td>
                                        <td>
                                            145.725
                                        </td>
                                        <td>
                                            145.125
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2HKA
                                        </td>
                                        <td>
                                            The Peak 太平山
                                        </td>
                                        <td>
                                            145.75
                                        </td>
                                        <td>
                                            145.15
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2UTM
                                        </td>
                                        <td>
                                            Tin Shu Wai 天水圍
                                        </td>
                                        <td>
                                            145.775
                                        </td>
                                        <td>
                                            145.175
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2DSR
                                        </td>
                                        <td>
                                            Mid Level 半山
                                        </td>
                                        <td>
                                            145.825
                                        </td>
                                        <td>
                                            145.225
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2RDS
                                        </td>
                                        <td>
                                            -
                                        </td>
                                        <td>
                                            430.0125
                                        </td>
                                        <td>
                                            430.012
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2UTM
                                        </td>
                                        <td>
                                            Tin Shu Wai 天水圍
                                        </td>
                                        <td>
                                            435.225
                                        </td>
                                        <td>
                                            430.225
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2HKA
                                        </td>
                                        <td>
                                            The Peak 太平山
                                        </td>
                                        <td>
                                            435.25
                                        </td>
                                        <td>
                                            430.25
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2KP
                                        </td>
                                        <td>
                                            Tate's Cair 大老山
                                        </td>
                                        <td>
                                            435.275
                                        </td>
                                        <td>
                                            430.275
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2RCU
                                        </td>
                                        <td>
                                            Yau Ma Tei 油麻地
                                        </td>
                                        <td>
                                            435.325
                                        </td>
                                        <td>
                                            430.325
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2R
                                        </td>
                                        <td>
                                            Tai Mo Shan 大帽山
                                        </td>
                                        <td>
                                            435.4
                                        </td>
                                        <td>
                                            430.4
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2TIG Ngau Chi
                                        </td>
                                        <td>
                                            -
                                        </td>
                                        <td>
                                            435.45
                                        </td>
                                        <td>
                                            430.45
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2HAC
                                        </td>
                                        <td>
                                            Sai Kung 西貢
                                        </td>
                                        <td>
                                            435.475
                                        </td>
                                        <td>
                                            430.475
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2JAM
                                        </td>
                                        <td>
                                            Wan Chai 灣仔
                                        </td>
                                        <td>
                                            435.55
                                        </td>
                                        <td>
                                            430.55
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2SSP
                                        </td>
                                        <td>
                                            Shum Shui Po 深水埗
                                        </td>
                                        <td>
                                            435.575
                                        </td>
                                        <td>
                                            430.575
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2AR
                                        </td>
                                        <td>
                                            Chai Wan 柴灣
                                        </td>
                                        <td>
                                            435.6
                                        </td>
                                        <td>
                                            430.6
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2Z
                                        </td>
                                        <td>
                                            Lantau 大嶼山 大東山
                                        </td>
                                        <td>
                                            435.625
                                        </td>
                                        <td>
                                            430.625
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2CWA
                                        </td>
                                        <td>
                                            Shum Shui Po 深水埗
                                        </td>
                                        <td>
                                            435.65
                                        </td>
                                        <td>
                                            430.65
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2HKR
                                        </td>
                                        <td>
                                            North Point 北角
                                        </td>
                                        <td>
                                            435.7
                                        </td>
                                        <td>
                                            430.7
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2FAN
                                        </td>
                                        <td>
                                            Fanling 粉嶺
                                        </td>
                                        <td>
                                            435.725
                                        </td>
                                        <td>
                                            430.725
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2POL
                                        </td>
                                        <td>
                                            Sheung Wan 上環
                                        </td>
                                        <td>
                                            435.8
                                        </td>
                                        <td>
                                            430.8
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2FTU
                                        </td>
                                        <td>
                                            Mongkok 旺角
                                        </td>
                                        <td>
                                            435.825
                                        </td>
                                        <td>
                                            430.825
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2DMR
                                        </td>
                                        <td>
                                            Tai Po 大埔
                                        </td>
                                        <td>
                                            435.9
                                        </td>
                                        <td>
                                            430.9
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2HKP
                                        </td>
                                        <td>
                                            Lai King 荔景
                                        </td>
                                        <td>
                                            435.925
                                        </td>
                                        <td>
                                            430.925
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            VR2JPO
                                        </td>
                                        <td>
                                            Mid Level 半山
                                        </td>
                                        <td>
                                            435.95
                                        </td>
                                        <td>
                                            430.95
                                        </td>
                                        <td>
                                            110.9 Hz
                                        </td>
                                    </tr>


                                    </tbody>
                                </table>

                                <p>* 以上 VR2 中繼及 409MHz 頻道資料來自「香港業餘無線電會 HKARA 」
                                </p>


                    </div>
                </div>

            </div>

        </Layout>

    )
}

export default IndexPage
